import React from 'react';

import { Link, HouseIcon } from './styledComponents';

function BackButton() {
  return (
    <Link href={process.env.REACT_APP_ADMIN_PERF_URL}>
      <HouseIcon alt="TimeOne Performance" />
      Performance
    </Link>
  );
}

export default BackButton;
