import styled from 'styled-components';

import House from '../../assets/Icons/House';

export const Link = styled.a`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  height: 100%;
`;

export const HouseIcon = styled(House)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;
