import posthog from 'posthog-js';

import { isPosthogActive } from '../utils';

export function init() {
  if (!isPosthogActive()) {
    return;
  }

  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    capture_pageview: false,
    advanced_disable_toolbar_metrics: true,
  });
}

export default { init };
