import { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'timeone-components';
import { useHistory, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

import serviceUser from '../services/user';
import { isPosthogActive } from '../utils';

function getUserTablesPreferences(preferenceEntityId, preferenceKeyBase) {
  const key = `${preferenceKeyBase}:${preferenceEntityId}`;

  return serviceUser.getUserPreference(key);
}

function getUserActiveTablesPreferences(preferenceEntityId, preferenceKeyBase) {
  const key = `${preferenceKeyBase}:active:${preferenceEntityId}`;

  return serviceUser.getUserPreference(key);
}

async function createOrUpdateUserActiveTablesPreferences(
  preferenceEntityId,
  preferenceKeyBase,
  preference,
  activeTableId,
  isFirstPlace
) {
  const key = `${preferenceKeyBase}:active:${preferenceEntityId}`;

  if (preference?.id) {
    const value = isFirstPlace
      ? [activeTableId, ...JSON.parse(preference.value)]
      : [...JSON.parse(preference.value), activeTableId];

    return serviceUser.updateUserPreference(preference.id, key, value);
  }

  return serviceUser.createUserPreference(key, [activeTableId]);
}

function hasChangesInTables(tablesChanges) {
  return Object.values(tablesChanges).filter(Boolean).length > 0;
}

function getLimitedSizeTitle(title) {
  const titleLimitSize = 13;

  return title?.length > titleLimitSize ? `${title.slice(0, titleLimitSize)}...` : title;
}

export default function usePreferredStatistics({ preferenceEntityId, preferenceKeyBase }) {
  const history = useHistory();
  const location = useLocation();
  const [userPreferenceTables, setUserPreferenceTables] = useState();
  const [tableChangesHah, setTableChangesHah] = useState({});
  const [userActivePreferenceTables, setUserActivePreferenceTables] = useState();
  const [userActiveTables, setUserActiveTables] = useState([]);
  const unblockRoute = useRef(() => {});

  async function onCardConfigClick({ active, id }) {
    if (active) {
      setUserActiveTables(activeTables => [...activeTables, id]);
      await createOrUpdateUserActiveTablesPreferences(
        preferenceEntityId,
        preferenceKeyBase,
        userActivePreferenceTables,
        id
      );
    } else {
      const updatedValues = [...userActiveTables.filter(tableId => tableId !== id)];

      setUserActiveTables(updatedValues);
      await serviceUser.updateUserPreference(
        userActivePreferenceTables.id,
        userActivePreferenceTables.key,
        updatedValues
      );
    }

    return fetchUserActiveTablesPreferences(preferenceEntityId);
  }

  async function onTableRemove({ id, title }) {
    try {
      await serviceUser.deleteUserPreference(id);
      fetchUserTablesPreferences(preferenceEntityId);
      if (userActivePreferenceTables && userActivePreferenceTables.id) {
        const newUserActiveTables = userActiveTables.filter(tableId => tableId !== id);

        setUserActiveTables(newUserActiveTables);
        await serviceUser.updateUserPreference(
          userActivePreferenceTables.id,
          userActivePreferenceTables.key,
          newUserActiveTables
        );
        toast.success(`"${getLimitedSizeTitle(title)}" has been deleted`);

        fetchUserActiveTablesPreferences(preferenceEntityId);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchUserActiveTablesPreferences(id) {
    const [userActivePreferences] = await getUserActiveTablesPreferences(id, preferenceKeyBase);

    setUserActivePreferenceTables(userActivePreferences || {});

    if (userActivePreferences && userActivePreferences.value) {
      setUserActiveTables(JSON.parse(userActivePreferences.value));
    }
  }

  async function fetchUserTablesPreferences(id) {
    const userPreferences = await getUserTablesPreferences(id, preferenceKeyBase);

    setUserPreferenceTables(userPreferences.reverse());
  }

  useEffect(() => {
    if (!history) {
      return () => {};
    }

    if (hasChangesInTables(tableChangesHah)) {
      unblockRoute.current = history.block('Do you want to leave without saving changes?');

      return unblockRoute.current;
    }

    unblockRoute.current();

    return () => {};
  }, [history, tableChangesHah]);

  useEffect(() => {
    if (location?.graphData) {
      setTableChangesHah({ draft: true });
    }
  }, []);

  useEffect(() => {
    if (preferenceEntityId) {
      setUserPreferenceTables();
      setUserActivePreferenceTables();
      fetchUserTablesPreferences(preferenceEntityId);
      fetchUserActiveTablesPreferences(preferenceEntityId);
    }
  }, [preferenceEntityId]);

  const onSave = useCallback(
    async ({ notify, isDraft, ...state }) => {
      const key = `${preferenceKeyBase}:${preferenceEntityId}`;

      if (isPosthogActive()) {
        state?.defaultColumns?.forEach(column => posthog.capture(`${preferenceKeyBase}:column`, { column }));
      }

      try {
        if (state && state.id) {
          const { id } = await serviceUser.updateUserPreference(state.id, key, state);

          setTableChangesHah(currentTableChangesHah => ({ ...currentTableChangesHah, [id]: false }));
          toast.success(`"${getLimitedSizeTitle(state.title)}" has been updated`);

          return id;
        }

        const { id } = await serviceUser.createUserPreference(key, state);

        if (isDraft) {
          setTableChangesHah(currentTableChangesHah => ({ ...currentTableChangesHah, draft: false }));
        }

        if (notify ?? true) {
          toast.success(`"${getLimitedSizeTitle(state.title)}" has been created`);
        }

        fetchUserTablesPreferences(preferenceEntityId);

        setUserActiveTables(activeTables => [id, ...activeTables]);

        await createOrUpdateUserActiveTablesPreferences(
          preferenceEntityId,
          preferenceKeyBase,
          userActivePreferenceTables,
          id,
          true
        );
        fetchUserActiveTablesPreferences(preferenceEntityId);

        return id;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [preferenceEntityId, userActivePreferenceTables]
  );

  function onTableChange(tableId) {
    const key = tableId ?? 'draft';

    setTableChangesHah(currentTableChangesHah => ({ ...currentTableChangesHah, [key]: true }));
  }

  function onReset(tableId) {
    setTableChangesHah(currentTableChangesHah => ({ ...currentTableChangesHah, [tableId]: false }));
  }

  return {
    onSave,
    onTableChange,
    userPreferenceTables,
    userActivePreferenceTables,
    onReset,
    onTableRemove,
    userActiveTables,
    onCardConfigClick,
    locationGraphData: location?.graphData,
  };
}
