import { useState, useEffect } from 'react';

export default function useConfigFromURL(getConfig) {
  const [configFromUrl, setConfigFromUrl] = useState({});
  const searchParam = new URLSearchParams(window.location.search);

  async function prepareConfig() {
    const config = await getConfig();
    const filtersFromUrl = config.filters
      .map(f => searchParam.has(f.slug) && [f.slug, searchParam.getAll(f.slug)])
      .filter(Boolean);

    const defaultColumnFromUrl = filtersFromUrl
      .map(([slug]) => (config.default.indexOf(slug) === -1 ? slug : false))
      .filter(Boolean)
      .concat(config.default);

    const orderedColumns = [...config.metrics, ...config.groups]
      .map(({ slug }) => (defaultColumnFromUrl.indexOf(slug) !== -1 ? slug : false))
      .filter(Boolean);

    const mandatoryFiltersConfig = Object.fromEntries(filtersFromUrl);

    const filtersTitle = filtersFromUrl.reduce((acc, [key, values]) => {
      const filterType = config.filters.find(({ slug }) => slug === key);

      if (!filterType) {
        return acc;
      }
      const filters = values.map(id => `${filterType.label}: ${id}`);

      return [...acc, ...filters];
    }, []);

    setConfigFromUrl({
      mandatoryFiltersConfig,
      tableStatConfigFromURL: orderedColumns.length > 0 &&
        Object.keys(mandatoryFiltersConfig).length > 0 && {
          title: filtersTitle.join(', '),
          metrics: orderedColumns,
          filters: mandatoryFiltersConfig,
        },
    });
  }

  useEffect(() => {
    prepareConfig();
  }, []);

  return configFromUrl;
}
