import React, { useState } from 'react';
import moment from 'moment';
import { VisualStatistics } from 'timeone-components';

import PageLoader from '../../components/Loader';
import { useConfigFromURL, usePreferredStatistics } from '../../hooks';
import service from '../../services/dashboardEmailing';

const DATE_FORMAT = 'YYYY/MM/DD';

function DashboardEmailing() {
  const { tableStatConfigFromURL } = useConfigFromURL(service.fetchConfig);
  const [initialPeriod] = useState({
    startDate: moment().subtract(29, 'days').startOf('day').format(DATE_FORMAT),
    endDate: moment().endOf('day').format(DATE_FORMAT),
    format: DATE_FORMAT,
    presetSlug: 'last30Days',
  });

  const {
    onSave,
    onTableChange,
    userPreferenceTables,
    userActivePreferenceTables,
    onReset,
    onTableRemove,
    userActiveTables,
    onCardConfigClick,
  } = usePreferredStatistics({
    preferenceKeyBase: 'performance:dashboard_emailing:statistics',
    preferenceEntityId: 1,
  });

  return userPreferenceTables && userActivePreferenceTables ? (
    <VisualStatistics
      initialPeriod={initialPeriod}
      getConfig={service.fetchConfig}
      onSave={onSave}
      onTableChange={onTableChange}
      userPreferenceTables={userPreferenceTables}
      onReset={onReset}
      onTableRemove={onTableRemove}
      userActivePreferenceTables={userActiveTables}
      onCardConfigClick={onCardConfigClick}
      graphData={tableStatConfigFromURL || null}
    />
  ) : (
    <PageLoader>Fetching data</PageLoader>
  );
}

export default DashboardEmailing;
