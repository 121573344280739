import { token } from 'timeone-components';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

export const createUserPreferenceApi = (key, value) =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/user/user_preferences`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify({ key, value: JSON.stringify(value) }),
  });

export const updateUserPreferenceApi = (id, key, value) =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/user/user_preferences/${id}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify({ key, value: JSON.stringify(value) }),
  });

export const getUserPreferenceApi = key =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/user/user_preferences?key=${key}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const deleteUserPreferenceApi = id =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/user/user_preferences/${id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getUserApi = () =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/user/me`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export default {
  getUserPreferenceApi,
  createUserPreferenceApi,
  updateUserPreferenceApi,
  deleteUserPreferenceApi,
  getUserApi,
};
