import { handleFetchResponse } from '../utils';
import {
  getUserPreferenceApi,
  createUserPreferenceApi,
  updateUserPreferenceApi,
  deleteUserPreferenceApi,
  getUserApi,
} from './user.api.js';

export async function createUserPreference(key, value) {
  const response = await createUserPreferenceApi(key, value);

  return handleFetchResponse(response);
}

export async function updateUserPreference(id, key, value) {
  const response = await updateUserPreferenceApi(id, key, value);

  return handleFetchResponse(response);
}

export async function getUserPreference(key) {
  const response = await getUserPreferenceApi(key);

  return handleFetchResponse(response);
}

export async function deleteUserPreference(id) {
  const response = await deleteUserPreferenceApi(id);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }
}

export async function getUser() {
  const response = await getUserApi();

  return handleFetchResponse(response);
}

export default {
  createUserPreference,
  updateUserPreference,
  getUserPreference,
  deleteUserPreference,
  getUser,
};
