import { ApolloClient } from '@apollo/client';
import { apolloBoostDefaultConfig, token } from 'timeone-components';

export const { handleFetchAuthenticationError } = token;

export const client = new ApolloClient(
  apolloBoostDefaultConfig(
    process.env.REACT_APP_GRAPHQL_USER_API_URL,
    `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/oauth`
  )
);

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

export async function handleFetchResponse(response) {
  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }

  return response.json();
}

export default {
  handleFetchAuthenticationError,
  client,
};
