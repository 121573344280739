import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'timeone-components';

import { LoaderContainer } from './styledComponents';

export default function PageLoader({ children, fixed, rounded }) {
  return (
    <LoaderContainer fixed={fixed} rounded={rounded}>
      <Loader>{children}</Loader>
    </LoaderContainer>
  );
}

PageLoader.defaultProps = { children: '', fixed: false, rounded: null };

PageLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  fixed: PropTypes.bool,
  rounded: PropTypes.string,
};
