import React, { useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import posthog from 'posthog-js';

import { ThemeProvider } from 'styled-components';
import { ApplicationLayout, themes, IsLogged, TimeOneRoutes, TimeOneConfig } from 'timeone-components';

import Dashboard from '../pages/Dashboard';
import DashboardEmailing from '../pages/DashboardEmailing';
import DashboardGlobal from '../pages/DashboardGlobal';
import DashboardInvoiceableStatistics from '../pages/DashboardInvoiceableStatistics';
import { headerProps, headerNavbarProps, sidebarProps, headerBlockRight } from './layoutData';
import { isPosthogActive } from '../utils';
import './App.css';

const { performance } = themes;
const shouldAppUseLogin = !['CI', 'test'].includes(process.env.NODE_ENV);

function Router() {
  const location = useLocation();

  useEffect(() => {
    if (isPosthogActive()) {
      posthog.capture('$pageview');
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/global" component={DashboardGlobal} />
      <Route exact path="/invoiceable_statistics" component={DashboardInvoiceableStatistics} />
      <Route exact path="/emailing" component={DashboardEmailing} />
      <TimeOneRoutes />
      <Redirect to="/" />
    </Switch>
  );
}
function Layout() {
  return (
    <ApplicationLayout
      className="App"
      headerProps={{ ...headerProps, ...headerNavbarProps, ...headerBlockRight }}
      sidebarProps={sidebarProps}
      withBreadcrumb={false}
    >
      <TimeOneConfig />
      <Router />
    </ApplicationLayout>
  );
}

function App() {
  return (
    <ThemeProvider theme={performance}>
      {shouldAppUseLogin ? (
        <IsLogged
          RenderComponent={Layout}
          authentificationUrl={`${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}`}
          cookieDomain={process.env.REACT_APP_COOKIE_DOMAIN_URL}
          oauthTokenUrl={process.env.REACT_APP_OAUTH_TOKEN_API_URL}
        />
      ) : (
        <Layout />
      )}
    </ThemeProvider>
  );
}

export default App;
