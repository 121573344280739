import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import moment from 'moment';
import 'moment/locale/en-gb';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { setFrameworkConfig } from 'timeone-components';
import { posthog, sentry } from './tools';
import { unregister } from './registerServiceWorker';

import 'react-dates/initialize';
import 'timeone-components/lib/styles.css';

import './index.css';
import App from './App';

unregister();
moment.locale('en-gb');

setFrameworkConfig({
  modules: { userInformations: false },
  urls: {
    graphqlUserUrl: process.env.REACT_APP_GRAPHQL_USER_API_URL,
    authenticationUrl: process.env.REACT_APP_AUTHENTICATION_SERVER_URL,
    oauthTokenUrl: process.env.REACT_APP_OAUTH_TOKEN_API_URL,
    cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN_URL,
  },
});

// Must be declare after the setFrameworkConfig method as it require frameworkConfig
posthog.init();
// sentry, fetchInterceptor and webVital use posthog feature so it MUST be called after posthog.init()
sentry.init();

const root = createRoot(document.getElementById('root'));

root.render(<App />);
