import React, { useEffect, useState } from 'react';
import posthog from 'posthog-js';

import { getUser } from '../../services/user/user.service';
import { UserContainer, NameContainer, LogoContainer } from './styledComponents';
import { isPosthogActive } from '../../utils';

function UserProfile() {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  const transformUser = name => {
    const userName = name
      .split(' ')
      .map((value, index, namesArray) => {
        if (index === namesArray.length - 1) {
          return value.toUpperCase();
        }
        return value.slice(0, 1).toUpperCase() + value.slice(1);
      })
      .join(' ');

    const userAllInitials = name.split(' ').map(value => value.slice(0, 1).toUpperCase());
    const userInitials = userAllInitials[0] + userAllInitials[userAllInitials.length - 1];

    return { userName, userInitials };
  };

  useEffect(() => {
    getUser()
      .then(({ id, name, email }) => {
        setUser(transformUser(name));
        if (isPosthogActive()) {
          posthog.identify(btoa(`perf:${id}`), { email, platform: 'perf.timeonegroup.com' });
        }
      })
      .catch(e => setError(e));
  }, []);

  if (!user || error) {
    return null;
  }
  return (
    <UserContainer>
      <NameContainer>{user.userName}</NameContainer>
      <LogoContainer>{user.userInitials}</LogoContainer>
    </UserContainer>
  );
}

export default UserProfile;
