import { token } from 'timeone-components';
import moment from 'moment';
import { handleFetchAuthenticationError } from '../utils';

const STAT_PERFORMANCE_EMAILING_ROOT_URL = `${process.env.REACT_APP_STAT_API_URL}/performance/emailing`;
const STAT_PERFORMANCE_EMAILING_CONF_URL = `${STAT_PERFORMANCE_EMAILING_ROOT_URL}/config`;
const STAT_PERFORMANCE_EMAILING_QUERY_URL = `${STAT_PERFORMANCE_EMAILING_ROOT_URL}/query`;
const STAT_PERFORMANCE_EMAILING_EXPORT_URL = `${STAT_PERFORMANCE_EMAILING_ROOT_URL}/export`;
const STAT_PERFORMANCE_EMAILING_EXPORT_STATUS_URL = `${STAT_PERFORMANCE_EMAILING_ROOT_URL}/export/status`;
const STAT_PERFORMANCE_EMAILING_EXPORT_DOWNLOAD_URL = `${STAT_PERFORMANCE_EMAILING_ROOT_URL}/export/download`;

// const STATS_MIN_START_DATE = '2017-01-01';

const headersContent = {
  'Content-Type': 'application/json',
};

function fetchConfig() {
  return fetch(STAT_PERFORMANCE_EMAILING_CONF_URL, {
    method: 'GET',
    mode: 'cors',
    headers: {
      authorization: `Bearer ${token.get()}`,
    },
  })
    .then(response => handleFetchAuthenticationError(response))
    .then(response => response.json());
}

function fetchData(payload) {
  return fetch(STAT_PERFORMANCE_EMAILING_QUERY_URL, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...headersContent,
      authorization: `Bearer ${token.get()}`,
    },
  })
    .then(response => handleFetchAuthenticationError(response))
    .then(response => response.json());
}

function fetchDataAvailability() {
  return fetchData({
    columns: ['campaignId'],
    period: {
      startDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    limit: 1,
  }).then(response => response.data && response.data.length > 0);
}

function requestExportFile(payload) {
  return fetch(STAT_PERFORMANCE_EMAILING_EXPORT_URL, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...headersContent,
      authorization: `Bearer ${token.get()}`,
    },
  })
    .then(response => handleFetchAuthenticationError(response))
    .then(response => response.json());
}

function fetchExportStatus(computationId) {
  return fetch(`${STAT_PERFORMANCE_EMAILING_EXPORT_STATUS_URL}/${computationId}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      ...headersContent,
      authorization: `Bearer ${token.get()}`,
    },
  })
    .then(response => handleFetchAuthenticationError(response))
    .then(response => response.json());
}

export default {
  fetchConfig,
  fetchData,
  fetchDataAvailability,
  requestExportFile,
  fetchExportStatus,
  EXPORT_DOWNLOAD_BASE_URL: STAT_PERFORMANCE_EMAILING_EXPORT_DOWNLOAD_URL,
};
