import styled from 'styled-components';
import { themes } from 'timeone-components';

export const UserContainer = styled.div`
  display: flex;
  height: 100%;
  padding-left: 2rem;
  align-items: center;
`;

export const NameContainer = styled.p`
  padding-right: 0.5rem;
`;

export const LogoContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  background-color: ${() => themes.performance.secondary};
`;
