import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import posthog from 'posthog-js';

import { isPosthogActive, isSentryActive } from '../utils';

export function init() {
  if (!isSentryActive()) {
    return;
  }

  const sentryIntegration = [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'advertiser-front-local.sandbox.localhost',
        'advertiser-front.sandbox.localhost',
        'advertiser.timeonegroup.com',
        /^\//,
      ],
    }),
    isPosthogActive() ? new posthog.SentryIntegration(posthog, 'sentry', 1) : null,
  ].filter(Boolean);

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_ENV,
    autoSessionTracking: false,
    integrations: sentryIntegration,
    release: process.env.REACT_APP_ENV === 'development' ? '0.0.0-dev' : process.env.npm_package_version,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

export default { init };
