import React from 'react';
import { themes } from 'timeone-components';
import BackButton from '../components/BackButton';
import UserProfile from '../components/UserProfile';
import { HeaderRightChildrenContainer } from './styledComponents';

const { timeone } = themes;

export const headerProps = {
  title: 'Statistics',
};

export const headerNavbarProps = {
  entries: [
    {
      id: '1',
      name: 'Dashboard',
      to: '/',
      exact: true,
    },
    {
      id: '2',
      name: 'Monitoring Dash',
      to: '/global',
      exact: true,
    },
    {
      id: '3',
      name: 'Dashboard invoiceable statistics',
      to: '/invoiceable_statistics',
      exact: true,
    },
    {
      id: '4',
      name: 'Dashboard Emailing ',
      to: '/emailing',
      exact: true,
    },
  ],
  extraEntries: [],
  selectedEntry: { name: '' },
};

export const headerBlockRight = {
  headerBlockRightChildren: () => (
    <HeaderRightChildrenContainer>
      <BackButton />
      <UserProfile />
    </HeaderRightChildrenContainer>
  ),
};

export const sidebarProps = {
  title: 'TimeOne Tools',
  entries: [
    {
      name: 'console',
      logo: <img width="35" height="35" src={timeone.header.logo.url} alt="TimeOne console" />,
      title: 'Console',
      url: 'https://console.timeonegroup.com/',
    },
  ],
};
