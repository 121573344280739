import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavLinkStyled = styled(NavLink)`
  &.active {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

export const HeaderRightChildrenContainer = styled.div`
  display: flex;
  height: 100%;
`;
